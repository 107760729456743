<app-user-layout>


<div class="px-4 sm:px-6 pt-[50px] w-full md:pt-[80px] pb-10 md:px-12 xl:px-12 2xl:px-24 2xl:pt-16">
  <!-- Top Div -->
  <div class="flex flex-col-reverse md:flex-row md:space-y-0">
    <!-- Wording DIV -->
     <div class="text-vendblocpurple md:mt-0 w-full md:w-2/3 lg:w-[65%] flex flex-col space-y-2 md:space-y-5 2xl:space-y-12">
      <div class="tracking-tight flex flex-col text-[32px] sm:text-[34px] md:text-[60px] lg:text-[60px] 2xl:text-[75px] font-bold xl:mb-1 leading-tight pt-2 md:pt-10">
        <span>Order From Your Favorite</span>
        <span> <span class="text-vendblocblue">Restaurants</span> here</span>
        <div class="w-[42%] -mt-5 md:-mt-8">
          <img src="../../../../assets/yellothing.png" class="object-contain h-10" alt="logo">
      </div>
      </div>
      
      <span
        class="px-2 sm:px-0 text-left font-normal text-sm md:text-[21px] md:leading-9 text-vendblocblue w-full md:w-[80%] 2xl:w-[65%] pl-1 md:pl-2">
        Craving something delicious? Explore a world of flavors and have your favorite meals delivered to your doorstep.</span>
      
        <div class="pt-5 mt-5">
        <button (click)="navigateToExplore()"
          class="flex justify-center space-x-1 bg-vendblocpurple w-full md:w-[50%] lg:w-[34%] lg rounded-3xl py-4 md:py-3 px-1 text-vendblocblue mt-6 lg:mt-2">
          <span class="pl-2 text-sm text-white md:text-[21px]">Explore Restaurants</span>
          <span class="pl-2 text-white pi pi-arrow-up-right"></span>
        </button>
      </div>

    </div> 
    <!-- Image Div -->
    <div
      class="hidden md:flex justify-center items-center md:justify-start w-full md:w-1/3 lg:w-[40%] md:items-start mb-5 md:mb-0 md:-ml-20">
      @defer (on idle){
        <div
        class="flex flex-col items-center lg:items-start justify-start xl:mt-14 2xl:mt-24 rounded-tl-[100px] rounded-b-[100px] w-[83%] md:w-full xl:w-[85%] 2xl:w-[70%] h-[250px] md:h-[300px] xl:h-[330px] 2xl:h-[400px] shadow-mine bg-cover bg-no-repeat">
        <img src="https://res.cloudinary.com/dw5cv0yz0/image/upload/v1718293821/VendBloc/landingimage_rrqhjd.png" alt="Landing image"
          class="object-cover w-full h-full rounded-tl-[100px] rounded-b-[100px] border" />
      </div>
      }
      
    </div>
  </div>

  <!-- Bottom Divs -->
  <div
    class="flex justify-between mt-16 md:mt-10 xl:mt-8 font-semibold text-base md:text-xl 2xl:text-2xl text-[#1A1B1F]">
    <span>Top Restaurants</span>
  </div>
  <!-- Restaurants -->
  <div class="flex mb-5">
    <div class="flex overflow-x-auto">
      @for (r of restaurants; track r.id) {
      <app-restaurant-card image={{r.imageUrl}} outletSlug={{r.slug}} outletId={{r.id}} store={{r.store}}
        outlet={{r.name}}></app-restaurant-card>
      }
    </div>
  </div>
</div>

</app-user-layout>