<app-user-layout (cartEventEmitter)="showCartDialog()" [showCart]="true"
    [cartCount]="this.cart.flat().length.toString()">
    @if(!isLoading){
    @if(isOutletOpen()){
    <div class="flex flex-col w-full h-full bg-white dark:bg-vendblocprimayDark">
        <div (click)="navigateBack()"
            class="px-5 lg:px-10 flex z-50 flex-row justify-start items-center  space-x-2 w-full cursor-pointer lg:ml-3  md:mt-2">
            <span class="font-bold text-sm md:text-xl pi pi-arrow-left text-vendblocpurple"> </span>
            <span class="py-0.5 md:py-2 md:pl-2 text-md md:text-lg text-vendblocpurple">Back</span>
        </div>

        <!-- Restaurant Header -->
        <!-- <div class="flex relative flex-col justify-center items-center w-full h-full text-white bg-transparent">
                    <div class="flex justify-end items-end w-[92%] md:w-[85%]">
                        <span
                            class="justify-end items-end text-lg cursor-pointer pi pi-heart-fill text-vendblocyellow md:text-xl"></span>
                    </div>
                    <span class="text-vendblocpurple font-bold text-2xl md:text-[50px] xl:text-5xl">{{
                        outlet?.store
                        }}</span>
                    <span class="mb-1 text-sm font-semibold text-vendblocgrey md:text-lg xl:text-xl">{{ outlet?.name
                        }}</span>
                    <span class="mb-2 text-sm font-semibold text-vendblocgrey md:text-lg xl:text-xl">{{ outlet?.address
                        }}</span>
                    <div class="flex flex-row justify-between w-[95%] md:w-[85%] mb-3 md:mb-0">
                        <div class="flex flex-row space-x-3 md:space-x-6">
                            <div class="flex flex-col justify-center items-center space-y-2">
                                <span class="text-sm font-bold text-vendblocpurple xl:text-lg">Delivery
                                    Time</span>
                                <span class="text-sm text-vendblocgrey md:text-base xl:text-lg"><span
                                        class="mr-1 text-vendblocyellow pi pi-car"></span>10-15 minutes</span>
                            </div>
                            <div class="flex flex-col justify-center items-center space-y-2">
                                <span class="text-sm font-bold text-vendblocpurple xl:text-lg">Opening
                                    Hours</span>
                                <span class="text-sm text-vendblocgrey md:text-base xl:text-lg"><span
                                        class="mr-1 text-vendblocyellow pi pi-stopwatch"></span>{{outlet?.opening}} -
                                    {{outlet?.closing}}</span>
                            </div>
                        </div>

                        <div class="flex flex-col justify-center items-center space-y-2">
                            <span class="text-sm font-bold text-vendblocpurple xl:text-lg">Rating</span>
                            <span
                                class="flex justify-center items-center space-x-4 text-sm text-vendblocgrey md:text-base xl:text-lg md:space-x-1">
                                4.0
                                <span class="flex pl-1 text-vendblocyellow md:hidden">
                                    <span class="pi pi-star-fill"></span>
                                </span>

                                <span class="hidden text-vendblocyellow md:flex">
                                    <span class="pi pi-star-fill"></span>
                                    <span class="pi pi-star-fill"></span>
                                    <span class="pi pi-star-fill"></span>
                                    <span class="pi pi-star-fill"></span>
                                    <span class="text-vendblocgrey pi pi-star-fill"></span>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="flex flex-col space-y-3 w-[75%] md:w-[40%] mt-2 md:my-10 ">
                        <div class="flex relative items-center w-full">
                            <input [(ngModel)]="searchQuery" (ngModelChange)="onSearch()"
                                class="py-2 md:py-3 pl-12 px-1 rounded-2xl w-full bg-[#F7F3F9] dark:bg-gray-700 focus:outline-none text-vendblocpurple dark:text-white"
                                type="text" placeholder="Search Food" id="mobileSearch" />
                            <button class="absolute pl-2">
                                <span
                                    class="w-8 text-lg font-bold text-gray-400 duration-200 pi pi-search hover:scale-110"></span>
                            </button>
                        </div>
                    </div>
                </div> -->

        <!-- </div> -->

        <!-- Restaurant Hero -->
        <div class="px-0 lg:px-10">


            <div [ngStyle]="{
                    'background-image': 'url(' + outlet?.bannerUrl || DEFAULT_RESTAURANT_IMG + ')',
                    'background-position': 'center',
                    'background-size': 'cover'
                  }" class=" xl:h-[273px] w-full relative lg:rounded-[10px] mt-5 mb-10">

                <!-- Black overlay -->
                <div class="absolute inset-0 bg-black bg-opacity-65 lg:rounded-[10px]"></div>

                <!-- Content -->
                <div class="relative z-10 text-white p-4">
                    <div class=" flex flex-col">
                        <div class="flex flex-col items-center xl:mt-14 xl:justify-center w-full ">
                            <h3 class="text-white text-2xl font-bold xl:text-5xl">{{outlet?.store}}</h3>
                            <h3 class="text-white text-[14px] mt-1d font-bold xl:text-2xl">{{outlet?.name}}</h3>
                            <h5 class="text-white text-[14px] mt-1d font-semibold">{{outlet?.address}}</h5>
                        </div>

                        <!-- absolute left-5 bottom-5 -->
                        <div class="flex pt-5  flex-col">
                            <div class="flex flex-row justify-between gap-4 xl:justify-start xl:gap-8">
                                <div class="flex flex-col gap-2">
                                    <h6 class="text-[14px] xl:text-base  text-white">Delivery Time</h6>
                                    <div class="flex gap-1">
                                        <img [src]="YELLOW_MOTORBIKE_ICON" alt="">

                                        <span class="font-bold text-sm md:text-base">
                                            10 - 15 minutes
                                        </span>
                                    </div>
                                </div>

                                <div class="flex flex-col gap-2">

                                    <h6 class="text-[14px] xl:text-base  text-white">Opening Time</h6>
                                    <div>
                                        <div class="flex gap-2 items-center">
                                            <span class=" text-vendblocyellow pi pi-stopwatch"></span>
                                            <span class="font-bold text-sm md:text-base">
                                                {{outlet?.opening}} -
                                                {{outlet?.closing}}
                                            </span>

                                        </div>

                                    </div>
                                </div>

                                <div class="xl:hidden flex flex-col justify-center items-center space-y-2">
                                    <div class="flex flex-col space-y-2">
                                        <h3 class="text-sm font-bold text-white xl:text-lg">Rating</h3>
                                        <div
                                            class="inline-flex gap-2 text-sm text-white md:text-base xl:text-lg md:space-x-1">
                                            4.5
                                            <app-app-star-rating [currentRating]="4.5"
                                                [readonly]="true"></app-app-star-rating>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="hidden xl:block absolute right-5 bottom-5">

                            <div class="flex flex-col space-y-2">
                                <h3 class="text-sm font-bold text-white xl:text-lg">Rating</h3>
                                <div class="inline-flex gap-2 text-sm text-white md:text-base xl:text-lg md:space-x-1">
                                    4.5
                                    <app-app-star-rating [currentRating]="4.5" [readonly]="true"></app-app-star-rating>
                                </div>
                            </div>
                        </div>

                        <div class="absolute top-3 right-3">
                            <span class="pi text-vendblocyellow pi-heart-fill"></span>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- Search bar -->

        <div class="px-5 lg:px-10 flex flex-col justify-center items-center ">
            <div class="max-w-[576px] flex relative items-center w-full">
                <input [(ngModel)]="searchQuery" (ngModelChange)="onSearch()"
                    class="py-2 md:py-3 pl-12 px-1 rounded-2xl w-full bg-[#F7F3F9] dark:bg-gray-700 focus:outline-none text-vendblocpurple dark:text-white"
                    type="text" placeholder="Search Food" id="mobileSearch" />
                <button class="absolute pl-2">
                    <span class="w-8 text-lg font-bold text-gray-400 duration-200 pi pi-search hover:scale-110"></span>
                </button>
            </div>
        </div>

        <!-- Cart Dialog -->
        <p-dialog [(visible)]="cartDialog" [style]="{ width: '450px' }" header="Cart" [modal]="true"
            styleClass="p-fluid">
            <ng-template pTemplate="content">
                <div class="m-0 space-y-6 w-full md:flex-col md:p-1 lg:p-3 xl:p-4">
                    <ng-container
                        *ngTemplateOutlet="cartCardTemplate; context: { uniqueID: 'cart-mobile' }"></ng-container>
                </div>
            </ng-template>
        </p-dialog>

        <!-- Delivery Instructions Dialog -->


        <app-dialog [visible]=showDeliveryLocationDialog header="Select your location" styleClass="p-fluid"
            (cancel)="toggleDeliveryLocationModal(false)" dismissableMask="false" closeOnEscape="false"
            [closable]="false" (hide)="toggleDeliveryLocationModal(false)"
            (confirm)="toggleDeliveryLocationModal(false)">
            <div class="m-0 space-y-6 w-full md:flex-col md:p-1 lg:p-3 xl:p-4">
                <!-- Existing Location Options -->
                <div class="flex flex-col space-y-3">
                    @if(this.outlet){
                    <form>
                        @for (location of this.outlet.deliveryLocations; track $index) {
                        <div class="flex justify-between space-y-2">
                            <span class="font-bold"> {{location.location}} </span>
                            <input [(ngModel)]="selectedDeliveryLocation" (change)="deliveryModeChanged(1,$index)"
                                type="radio" value={{$index}} class="w-4 h-4 rounded accent-vendblocpurple"
                                id={{location.location}}{{$index}} name="deliveryLocation" />
                        </div>
                        }
                    </form>
                    }
                </div>
            </div>
        </app-dialog>

        <app-dialog [(visible)]="updateProfileDialog" (hide)="updateProfileDialog = false"
            (cancel)="updateProfileDialog = false" [showFooter]="false" width="450px"
            header="{{isAuthenticated ? 'Confirm Details' : 'Delivery Details'}}">
            @if(isAuthenticated){
            <!-- Saved Locations Cards -->
            <div class="flex flex-col gap-3 mb-4">
                <!-- Office Location Card -->
                <div
                    class="flex justify-between items-center p-3 rounded-lg border border-gray-200 cursor-pointer hover:border-vendblocpurple">
                    <div class="flex gap-3">
                        <div class="flex items-center">
                            <i class="text-xl pi pi-building text-vendblocpurple"></i>
                        </div>
                        <div class="flex flex-col">
                            <span class="font-semibold text-vendblocpurple">Office</span>
                            <span class="text-sm text-gray-600">Rio Nowakowska, Zabiniec 12/222</span>
                            <span class="text-sm text-gray-500">+79 123 456 789</span>
                        </div>
                    </div>
                    <div class="flex items-center">
                        <input type="radio" name="deliveryLocation" class="w-4 h-4 rounded accent-vendblocpurple" />
                    </div>
                </div>

                <!-- Home Location Card -->
                <div
                    class="flex justify-between items-center p-3 rounded-lg border border-gray-200 cursor-pointer hover:border-vendblocpurple">
                    <div class="flex gap-3">
                        <div class="flex items-center">
                            <i class="text-xl pi pi-home text-vendblocpurple"></i>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex gap-2 items-center">
                                <span class="font-semibold text-vendblocpurple">My home</span>
                                <span class="px-2 py-0.5 text-xs text-white rounded bg-vendblocpurple">Default</span>
                            </div>
                            <span class="text-sm text-gray-600">Sophi Nowakowska, Zabiniec 12/222</span>
                            <span class="text-sm text-gray-500">+79 123 456 789</span>
                        </div>
                    </div>
                    <div class="flex items-center">
                        <input type="radio" name="deliveryLocation" checked
                            class="w-4 h-4 rounded accent-vendblocpurple" />
                    </div>
                </div>

                <!-- Add New Address Button -->
                <button
                    class="flex items-center justify-center gap-2 p-3 border border-dashed border-vendblocpurple rounded-lg text-vendblocpurple hover:bg-[#F7F3F9] transition-colors">
                    <i class="pi pi-plus"></i>
                    <span>Add New Address</span>
                </button>
            </div>
            }

            <div class="m-0 space-y-2 w-full md:flex-col md:p-1 lg:p-3 xl:p-4">
                <form [formGroup]="profileUpdateForm" (ngSubmit)="updateProfile()">
                    <div class="flex flex-col space-y-3">
                        <!-- Cart list -->
                        <div class="form_div">
                            <app-text-input-field [formSubmitted]="profileUpdateFormSubmitted" ngDefaultControl
                                label="Email" inputId="registerEmail" placeHolder="Input Your Email"
                                [formControl]="profileUpdateForm.controls.email"
                                inputType="email"></app-text-input-field>
                        </div>

                        <div class="form_div">
                            <label for="phone">Phone Number</label>
                            <input id="phone" type="tel" formControlName="phone" (input)="onPhoneInput($event)"
                                class="p-3 rounded-md border" placeholder="Input Your Phone" required />
                            <div *ngIf="profileUpdateForm.get('phone')?.invalid && (profileUpdateForm.get('phone')?.dirty || profileUpdateForm.get('phone')?.touched)"
                                class="mt-1 text-sm text-red-500">
                                <div *ngIf="profileUpdateForm.get('phone')?.errors?.['required']">Phone number is
                                    required.</div>
                                <div *ngIf="profileUpdateForm.get('phone')?.errors?.['pattern']">Please enter only
                                    numbers.</div>
                            </div>
                        </div>
                        <div class="form_div">
                            <label for="">Address</label>
                            <input required class="p-3 rounded-md border" placeholder="Enter Your Address"
                                formControlName="address" type="text" required />
                        </div>
                        @if(isAuthenticated){
                        <div class="flex items-center space-x-2">
                            <input type="checkbox" id="termsCheckbox" formControlName="acceptTerms"
                                class="w-4 h-4 rounded accent-vendblocpurple" />
                            <label for="termsCheckbox" class="text-sm">Update my Profile</label>
                        </div>
                        }
                        <div class="form_div">
                            <button
                                class="flex justify-center p-3 space-x-4 text-white duration-300 bg-vendblocpurple create_button hover:shadow-lg"
                                type="submit">
                                <span>Save</span>
                            </button>
                        </div>
                        <div class="flex flex-col justify-between mt-4 w-full">
                            <app-info textItem="This is to enable vendors contact you regarding your orders"></app-info>
                        </div>
                    </div>
                </form>
            </div>
        </app-dialog>



        <!-- Content Div -->
        <div
            class="px-5 lg:px-10 flex flex-row justify-between items-center pt-4 mb-20 space-x-3 w-full  lg:space-x-6 md:items-start">
            <!-- Products Div -->
            <div class="flex flex-col md:w-[67%] w-full ">
                <div class="flex flex-col space-y-0">
                    <h3 class="mb-1 font-bold text-vendblocpurple text-lg dark:text-white">Food Categories</h3>
                    <div class="flex overflow-x-auto py-2">
                        @for(category of filteredCategories; track category.id) {
                        <div (click)="categoryProducts($event, category.id)" class="category_item_div group"
                            [ngClass]="{'text-white bg-vendblocpurple':activeCategory === category.id}">
                            <span class="flex px-1 whitespace-nowrap text-vendblocpurple group-hover:text-white"
                                [ngClass]="{'text-white':activeCategory === category.id}" href="">{{ category.name
                                }}</span>
                            <span class="opacity-50 text-vendblocpurple group-hover:text-white"
                                [ngClass]="{'text-white':activeCategory === category.id}">{{ category.quantity
                                }}</span>
                        </div>
                        }
                    </div>
                </div>
                <!--  [ngClass]="{
                            'grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4': cart.length > 0
                        }"                     'grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5': cart.length === 0, -->
                <div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                    @for(product of filteredProducts; track product.productId) {
                    <app-order-product-card [cardName]="product.product" [iconBackgroundColor]="'vendblocpurple'"
                        [cardCount]="product.quantity.toString()" [icon]="'cart'" [product]="product"
                        (addToCartEvent)="addProductToCart(product)"
                        (incrementDecrementQuantity)="handleProductQuantityIncrementDecrement($event)"
                        (directQuantityChange)="handleDirectQuantityInput($event)"></app-order-product-card>

                    }
                </div>
                @if(filteredProducts.length === 0) {
                <div class="text-lg text-center md:text-xl">
                    <div class="flex items-center justify-center">
                        <span class="text-primary">No products found matching your search.</span>
                    </div>
                </div>
                }

            </div>
            <!-- My Cart -->
            <div class="md:w-[33%]" [ngClass]="cart.length === 0 ? 'hidden' : 'hidden md:block'">
                <div class="space-y-6 border md:flex md:flex-col border-grey-300 md:p-1 lg:p-3 xl:p-4">
                    <ng-container
                        *ngTemplateOutlet="cartCardTemplate; context: { uniqueID: 'cart-web' }"></ng-container>
                </div>
            </div>
        </div>
    </div>
    } @else {
    <div class="px-5 lg:px-10 flex flex-col justify-center items-center w-full h-full">
        <div
            class="flex flex-col w-full lg:w-[55%] h-full justify-center items-center pt-[30%] lg:pt-[15%] xl:pt:20 px-8 md:px-24 lg:px-20 xl:px-28">
            <h1
                class="justify-center items-center text-2xl font-bold text-center lg:text-4xl text-gray-800 dark:text-gray-500">
                Outlet is closed</h1>
            <p class="text-lg text-center text-gray-500">This outlet is currently closed, Please check back tomorrow
                at {{outlet?.opening}} for our next opening</p>
            <button class="justify-center items-center w-[200px] px-4 py-2 mt-4 text-white rounded-md bg-vendblocpurple"
                (click)="navigateBack()">Back to listings</button>
        </div>
    </div>
    }
    }
    <ng-template #cartCardTemplate let-uniqueID="uniqueID">
        <app-cart-card [uniqueId]="uniqueID" (repeatOrderEvent)="repeatOrder()" (deleteOrderEvent)="deleteOrder($event)"
            (vendorInstructionsChanged)="onVendorInstructionsChanged($event)"
            (deliveryInstructionsChanged)="onDeliveryInstructionsChanged($event)"
            (quantityChangedEmitter)="onCartQuantityChange($event)" [ordersCost]="ordersCost" [outlet]="outlet"
            (changeDeliveryAddressEvent)="toggleProfileModal()" [user]="user" [totalCost]="totalCost"
            [serviceCharge]="serviceCharge" [deliveryFee]="deliveryFee" [deliveryLocation]="deliveryLocation"
            (newOrder)="newOrder()" (deliveryModeChanged)="deliveryModeChanged($event)" (clearCart)="clearCart()"
            (removeFromCart)="removeFromCart($event)" (incrementDecrementEvent)="cartItemIncrementDecrement($event)"
            [cart]="cart" (checkout)="checkout()" [selectedDeliveryMode]="selectedDeliveryMode">
        </app-cart-card>
    </ng-template>