import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, OrderService } from '@data-access/services';
import { Order, ResponseDTO, User } from '@models/index';
import { format } from 'date-fns';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { first } from 'rxjs';
import { TableComponent } from "../table/table.component";
import { PrimeTableColumn } from '@models/prime-interface/prime-interface';
import { AdminLayoutComponent } from "../layouts/admin-layout/admin-layout.component";
import { formatCurrency } from '@utils/shared/shared';

@Component({
    selector: 'app-my-orders',
    standalone: true,
    templateUrl: './my-orders.component.html',
    styleUrl: './my-orders.component.css',
    imports: [CommonModule, TableModule, DialogModule, ButtonModule, TableComponent, AdminLayoutComponent]
})
export class MyOrdersComponent implements OnInit {

    user: User | null = null;
    orders: Order[] = [];
    showDetail: boolean = false;
    dialogVisible: boolean = false;
    currentInvoiceOrder: any = null;
    currency: string = 'NGN';

    tableCols: PrimeTableColumn[] = [
        { field: 'formattedDate', header: 'Date' },
        { field: 'store', header: 'Store' },
        { field: 'outlet', header: 'Outlet' },
        { field: 'deliveryMode', header: 'Delivery' },
        { field: 'status', header: 'Status' },
        { field: 'paid', header: 'Paid' },
        { field: 'formattedTotalAmount', header: 'Amount' }
    ];

    showDialog(index: number) {
        this.currentInvoiceOrder = this.orders[index];
        this.dialogVisible = true;
    }

    constructor(
        private orderService: OrderService,
        private authService: AuthService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.authService.user$.pipe(first()).subscribe((response) => {
            this.user = response;
        });
        this.fetchOrders();
        // You can set the currency here based on user preferences or any other logic
        // this.currency = 'USD'; // Example of changing currency
    }

    showDetailEvent() {
        this.showDetail = !this.showDetail;
    }

    fetchOrders() {
        const email = this.user?.email as string;
        if (!email) this.router.navigate(['login']);
        this.orderService.getUserOrders(email).pipe(first()).subscribe({
            next: (result: ResponseDTO) => {
                if (result.status) {
                    const resultOrders = result.data as Order[]; 
                    this.orders = resultOrders.map((o: Order) => {
                        const totalAmount = o.amount + o.serviceCharge + o.deliveryCost;
                        return { 
                            ...o, 
                            formattedDate: format(new Date(o.orderDate), 'dd MMM yyyy, HH:mm'),
                            totalAmount: totalAmount,
                            formattedTotalAmount: formatCurrency(totalAmount, this.currency)
                        };
                    });
                }
            },
            error: (e) => {
                console.log(e);
            }
        });
    }
}
