import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadingService, OrderService } from '@data-access/services';

@Component({
  selector: 'app-verify-payment',
  standalone: true,
  imports: [],
  templateUrl: './verify-payment.component.html',
  styleUrl: './verify-payment.component.css'
})
export class VerifyPaymentComponent implements OnInit {
  isLoading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService,
    private toastr: ToastrService,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const reference = params['reference'];
      const gateway = params['gateway'];
      
      if (reference && gateway) {
        this.verifyPayment(reference, gateway);
      } else {
        this.router.navigate(['/my-orders']);
      }
    });
  }

  private verifyPayment(reference: string, gateway: string) {
    this.loadingService.show();
    this.isLoading = true;
    
    this.orderService.verifyPayment(reference, gateway).subscribe({
      next: (response) => {
        this.loadingService.hide();
        this.isLoading = false;
        this.toastr.success('Payment verified successfully');
        this.router.navigate(['/my-orders']);
      },
      error: (error) => {
        this.loadingService.hide();
        this.isLoading = false;
        console.error('Payment verification failed:', error);
        
        const errorMessage = error.error?.message || error.error || 'Payment verification failed';
        this.toastr.error(errorMessage);
        
        setTimeout(() => {
          this.router.navigate(['/my-orders']);
        }, 2000);
      }
    });
  }
}
