<app-user-layout [showCart]="false">

    <div class="px-5 lg:px-10 flex flex-col w-full bg-white dark:bg-vendblocprimayDark pb-20">
        <div (click)="navigateHome()"
            class=" flex z-50 flex-row justify-start items-center  space-x-2 w-full cursor-pointer lg:ml-3  md:mt-2">
            <span class="font-bold text-sm md:text-xl pi pi-arrow-left text-vendblocpurple"> </span>
            <span class="py-0.5 md:py-2 md:pl-2 text-md md:text-lg text-vendblocpurple">Back</span>
        </div>
        <div class="w-full">
            <div class="flex flex-col mx-auto justify-center items-center gap-6 py-4">
                <span class="text-2xl font-bold md:text-4xl xl:text-5xl 2xl:text-6xl text-vendblocpurple">Our
                    Restaurants</span>
                @if(!searching){
                <span class="text-sm md:text-lg  text-center font-normal text-gray-400">You
                    can order from your preferred
                    Restaurants</span>
                }
                <div class="w-full max-w-[579px]">
                    <!-- (input)="search()" -->
                    <input #searchQuery (input)="searchRestaurant(searchQuery.value)"
                        class="py-2 px-[18px] w-full bg-[#7676801f] focus:outline-none rounded-3xl placeholder:text-sm md:placeholder:text-base"
                        type="text" placeholder="Search Restaurants in your city" />
                </div>
            </div>
        </div>
        <!-- @if(searching){
    <div class="px-5 w-full md:px-12 xl:px-12 2xl:px-24">
        @if(!doneSearching){
            <div class="flex justify-between mt-10 xl:mt-5 mb-1 font-semibold text-base md:text-xl 2xl:text-2xl text-[#1A1B1F]">
                <span>Searching...</span>
            </div>
        } @else {
            @if(searchResult.length > 0){
                <div class="flex justify-between mt-10 xl:mt-5 mb- font-semibold text-base md:text-xl 2xl:text-2xl text-[#1A1B1F]">
                    <span>Search Results</span>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    @for (r of searchResult; track r.id) {
                        <app-restaurant-card outletSlug={{r.slug}} image={{r.bannerUrl}} outletId={{r.id}} store={{r.store}}
                            outlet={{r.name}}></app-restaurant-card>
                    }
                </div>
            } @else {
                <div class="flex justify-between mt-10 xl:mt-5 mb-1 font-semibold text-base md:text-xl 2xl:text-2xl text-[#1A1B1F]">
                    <span>No Result found</span>
                </div>
            }
        }
    </div>
    } -->
        <!-- @else{ -->

        @if(searching){
        <div class="">
            <p class="text-base text-primary font-semibold">
                Searching...
            </p>
        </div>
        }

        

        @if(restaurants.length > 0 && !searching){
        <div class=" w-full mt-4">
            <div
                class="flex justify-between items-center mt-10 xl:mt-5 mb-1 font-semibold text-base md:text-xl 2xl:text-2xl text-[#1A1B1F]">
                <h3 class="text-vendblocprimayDark dark:text-white">Nearby Restaurants</h3>
                <div class="lg:hidden text-xs text-gray-500 animate-pulse">
                    Scroll for more →
                </div>
            </div>
            <div class="w-full mt-8">
                <div
                    class="custom-scrollbar flex overflow-x-scroll gap-5 lg:grid  lg:overflow-hidden lg:gap-8 lg:grid-cols-3 xl:grid-cols-4">
                    @for (r of restaurants; track r.id) {
                    <app-restaurant-card icon={{r.iconUrl}} banner={{r.bannerUrl}} outletSlug={{r.slug}} outletId={{r.id}} store={{r.store}}
                        outlet={{r.name}}></app-restaurant-card>
                    }
                </div>
            </div>
        </div>
        } 

        @if(restaurants.length === 0 && !searching){
        <div
            class="w-full flex flex-col items-center justify-between mt-10 xl:mt-5 mb-1 font-semibold text-base md:text-xl 2xl:text-2xl text-[#1A1B1F]">
            <h3 class="text-primary">No Result found</h3>
        </div>
        }

        <!-- } -->
    </div>

</app-user-layout>