import { Component, EventEmitter, Input } from '@angular/core';
import { CartCardComponent } from "../shared/cart-card/cart-card.component";
import {  FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { InfoComponent } from "../info/info.component";
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { BadgeModule } from 'primeng/badge';
import { UserLayoutComponent } from "../layouts/user-layout/user-layout.component";
import { OrderProductCardComponent } from "../shared/order-product-card/order-product-card.component";
import { OrderComponent } from '@components/order/order.component';
import { AppDialogComponent } from "../shared/app-dialog/app-dialog.component";
import { AvatarImageComponent } from "../shared/avatar-image/avatar-image.component";

@Component({
  selector: 'app-order-v2',
  standalone: true,
  imports: [CommonModule, FormsModule,
    ReactiveFormsModule, CartCardComponent, AvatarModule, AvatarGroupModule, InfoComponent, DialogModule, BadgeModule, UserLayoutComponent, OrderProductCardComponent, AppDialogComponent, AvatarImageComponent],
  templateUrl: './order-v2.component.html',
  styleUrl: './order-v2.component.css'
})


export class OrderV2Component extends OrderComponent {
  @Input() parentCartEvent: EventEmitter<any> = new EventEmitter<any>();
  showDetailDialog: any;
  deliveryInstructionsDialog: any;

  toggleDeliveryInstructionsDialog() {
    this.deliveryInstructionsDialog = !this.deliveryInstructionsDialog;
  }
}