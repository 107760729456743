<app-admin-layout>
  <app-table [cols]="tableCols" [enableShow]="true" (showButtonPressed)="showDialog($event)" [tableData]="orders"
    tableNamePlural="Orders" tableNameSingular="Order"></app-table>

  <p-dialog header="Order Invoice" [(visible)]="dialogVisible" [modal]="true"
  [breakpoints]="{ '1000px': '90vw' }" 
    [style]="{ width: '50vw', height: '500px' }" [draggable]="false" [resizable]="false">
    <div class="flex flex-col space-y-2">
      <div class="flex justify-between">
        <span>Date : {{currentInvoiceOrder?.formattedDate}}</span>
        <span>Total : {{currentInvoiceOrder?.totalAmount | currency:currency:'symbol':'1.2-2'}}</span>
      </div>
      <div class="flex justify-between">
        <span>Store : {{currentInvoiceOrder?.store }}-({{currentInvoiceOrder?.outlet}})</span>
        <span>Order Status : {{currentInvoiceOrder?.status}}</span>
      </div>
      <div class="flex justify-between">
        <span>Delivery : {{currentInvoiceOrder?.deliveryCost | currency:currency:'symbol':'1.2-2'}}</span>
        <span>Service Charge : {{currentInvoiceOrder?.serviceCharge | currency:currency:'symbol':'1.2-2'}}</span>
      </div>

      <div>
        <p-table [value]="currentInvoiceOrder?.products" [tableStyle]="{ 'min-width': '30rem' }">
          <ng-template pTemplate="header">
            <tr>
              <th>S/N</th>
              <th>Name</th>
              <th>Amount</th>
              <th>Quantity</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
            <tr>
              <td>{{rowIndex + 1 }}</td>
              <td>{{ product.product }}</td>
              <td>{{ product.amount | currency:currency:'symbol':'1.2-2' }}</td>
              <td>{{ product.orderQuantity }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </p-dialog>
</app-admin-layout>
