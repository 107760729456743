import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-app-star-rating',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './star-rating.component.html',
  styleUrl: './star-rating.component.css'
})
export class AppStarRatingComponent {
  @Input() currentRating: number = 0;
  @Input() readonly: boolean = false;
  @Input() maxStars: number = 5;
  @Output() ratingChange = new EventEmitter<number>();

  get fullStars(): number {
    return Math.floor(this.currentRating); // Get full stars count
  }

  get decimalPercentage(): number {
    return (this.currentRating % 1) * 100; // Convert decimal part to percentage (e.g., 0.7 -> 70%)
  }

  get stars(): number[] {
    return Array(this.maxStars).fill(0).map((_, i) => i + 1);
  }

  setRating(rating: number) {
    if (!this.readonly) {
      this.currentRating = rating;
      this.ratingChange.emit(rating);
    }
  }
}


