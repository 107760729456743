import { Component, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-prime-toast',
  standalone: true,
  imports: [ToastModule, ButtonModule, RippleModule],
  providers: [MessageService],
  templateUrl: './prime-toast.component.html',
  styleUrl: './prime-toast.component.css'
})
export class PrimeToastComponent {
  constructor(private messageService: MessageService,private toastr: ToastrService) {}

  @Input() severity: string = '';
  @Input() detail: string = '';

  show() {
      // this.messageService.add({ severity: this.severity, summary: this.severity, detail: this.detail });
  this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Sample message sent successfully' });
    }

    show4() {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });
    }

  show2() {
    this.toastr.success("Success", "Sample message sent successfully");
  }

  show3() {
    this.toastr.error("Error", "Something went wrong");
  }
}
