<app-user-layout (cartEventEmitter)="showCartDialog()" [showCart]="true"
    [cartCount]="this.cart.flat().length.toString()">


    <div class="flex flex-col w-full h-full">
        <div class="flex z-50 flex-row justify-start items-center pl-2 w-auto md:pl-5 md:mt-2">
            <div (click)="navigateBack()" class="flex flex-row justify-center items-center space-x-2 cursor-pointer md:space-x-4">
                <span class="font-bold text-md md:text-2xl pi pi-arrow-left text-vendblocpurple"> </span>
                <span class="py-0.5 md:py-2 md:pl-2 text-md md:text-lg text-vendblocpurple">Back</span>
            </div>
        </div>

        <!-- Restaurant Header -->
        <div class="flex relative flex-col justify-center items-center w-full h-full text-white bg-transparent">
            <div class="flex justify-end items-end w-[92%] md:w-[85%]">
                <span
                    class="justify-end items-end text-lg cursor-pointer pi pi-heart-fill text-vendblocyellow md:text-xl"></span>
            </div>
            <span class="text-vendblocpurple font-bold text-2xl md:text-[50px] xl:text-5xl">{{
                outlet?.store
                }}</span>
            <span class="mb-5 text-sm font-semibold text-vendblocgrey md:text-lg xl:text-xl">{{ outlet?.name
                }}</span>
            <div class="flex flex-row justify-between w-[95%] md:w-[85%] mb-3 md:mb-0">
                <div class="flex flex-row space-x-3 md:space-x-6">
                    <div class="flex flex-col space-y-2">
                        <span class="text-sm font-bold text-vendblocpurple xl:text-lg">Delivery
                            Time</span>
                        <span class="text-sm text-vendblocgrey md:text-base xl:text-lg"><span
                                class="mr-1 md:mr-3 text-vendblocyellow pi pi-car"></span>10-15 minutes</span>
                    </div>
                    <div class="flex flex-col space-y-2">
                        <span class="text-sm font-bold text-vendblocpurple xl:text-lg">Opening
                            Hours</span>
                        <span class="text-sm text-vendblocgrey md:text-base xl:text-lg"><span
                                class="mr-1 md:mr-3 text-vendblocyellow pi pi-stopwatch"></span> {{outlet?.opening}} -
                            {{outlet?.closing}}</span>
                    </div>
                </div>

                <div class="flex flex-col justify-center items-center space-y-2">
                    <span class="text-sm font-bold text-vendblocpurple xl:text-lg">Rating</span>
                    <span class="flex justify-center items-center space-x-4 text-sm text-vendblocgrey md:text-base xl:text-lg md:space-x-1"> 4.0
                        <span class="flex pl-1 text-vendblocyellow md:hidden">
                            <span class="pi pi-star-fill"></span>
                        </span>

                        <span class="hidden text-vendblocyellow md:flex">
                            <span class="pi pi-star-fill"></span>
                            <span class="pi pi-star-fill"></span>
                            <span class="pi pi-star-fill"></span>
                            <span class="pi pi-star-fill"></span>
                            <span class="text-vendblocgrey pi pi-star-fill"></span>
                        </span>
                    </span>
                </div>
            </div>
            <div class="flex flex-col space-y-3 w-[75%] md:w-[40%] mt-2 md:mt-4 ">
                <div class="flex relative items-center w-full">
                    <input [(ngModel)]="searchQuery" (ngModelChange)="onSearch()"
                        class="py-2 md:py-3 pl-12 px-1 rounded-2xl w-full bg-[#F7F3F9] focus:outline-none text-vendblocpurple"
                        type="text" placeholder="Search Food" id="mobileSearch" />
                    <button class="absolute pl-2">
                        <span
                            class="w-8 text-lg font-bold text-gray-400 duration-200 pi pi-search hover:scale-110"></span>
                    </button>
                </div>
            </div>
        </div>
        <!--  -->

        <!-- </div> -->

        <!-- Cart Dialog -->
        <p-dialog [(visible)]="cartDialog" [style]="{ width: '450px' }" header="Cart" [modal]="true"
            styleClass="p-fluid">
            <ng-template pTemplate="content">
                <div class="m-0 space-y-6 w-full md:flex-col md:p-1 lg:p-3 xl:p-4">
                    <app-cart-card  (repeatOrderEvent)="repeatOrder()" (deleteOrderEvent)="deleteOrder($event)" [deliveryInstructionsForm]="orderInstructionsForm.controls.deliveryInstructions"
                        [vendorInstructionsForm]="orderInstructionsForm.controls.vendorInstructions"
                        (quantityChangedEmitter)="onCartQuantityChange($event)" uniqueId="mobile"
                        [ordersCost]="ordersCost" [outlet]="outlet" (changeDeliveryAddressEvent)="toggleProfileModal()"
                        [user]="user" [totalCost]="totalCost" [serviceCharge]="serviceCharge"
                        [deliveryFee]="deliveryFee" [deliveryLocation]="deliveryLocation" (newOrder)="newOrder()"
                        (deliveryModeChanged)="deliveryModeChanged($event)" (clearCart)="clearCart()"
                        (removeFromCart)="removeFromCart($event)" (updateCartQuantity)="updateCartItemQuantity($event)"
                        [cart]="cart" (checkout)="checkout()"
                        [selectedDeliveryMode]="selectedDeliveryMode"></app-cart-card>
                </div>
            </ng-template>
        </p-dialog>

         <!-- Delivery Instructions Dialog -->
     

        <p-dialog [(visible)]="deliveryLocationDialog" [style]="{ width: '450px' }" header="Select your location"
            [modal]="true" styleClass="p-fluid">
            <ng-template pTemplate="content">
                <div class="m-0 space-y-6 w-full md:flex-col md:p-1 lg:p-3 xl:p-4">
                    <div class="flex flex-col space-y-3">
                        @if(this.outlet){
                        <form>
                            @for (location of this.outlet.deliveryLocations; track $index) {
                            <div class="flex justify-between space-y-2">
                                <span class="font-bold"> {{location.location}} </span>
                                <input [(ngModel)]="selectedDeliveryLocation" (change)="deliveryLoctionChanged($index)"
                                    type="radio" value={{$index}} class="w-4 h-4 rounded accent-vendblocblue"
                                    id={{location.location}}{{$index}} name="deliveryLocation" />
                            </div>
                            }
                            <div class="form_div">
                                <button style="background-color: #0f172a" (click)="toggleDeliveryLocationModal()"
                                    class="flex justify-center p-3 space-x-4 text-white duration-300 create_button hover:shadow-lg"
                                    type="button">
                                    <span>Done</span>
                                </button>
                            </div>
                        </form>
                        }
                    </div>
                </div>
            </ng-template>
        </p-dialog>

        <p-dialog [(visible)]="updateProfileDialog" [style]="{ width: '450px' }" header="Update Details" [modal]="true"
            styleClass="p-fluid">
            <ng-template pTemplate="content">
                <div class="m-0 space-y-6 w-full md:flex-col md:p-1 lg:p-3 xl:p-4">
                    <form [formGroup]="profileUpdateForm" (ngSubmit)="updateProfile()">
                        <div class="flex flex-col space-y-3">
                            <!-- Cart list -->
                            @if(!this.user?.email){
                            <div class="form_div">
                                <label for="">Email</label>
                                <input required class="p-3 rounded-md border" placeholder="Input Your Email"
                                    formControlName="email" type="text" required />
                            </div>
                            }
                            <div class="form_div">
                                <label for="phone">Phone Number</label>
                                <input id="phone" type="tel" formControlName="phone" (input)="onPhoneInput($event)"
                                    class="p-3 rounded-md border" placeholder="Input Your Phone" required />
                                <div *ngIf="profileUpdateForm.get('phone')?.invalid && (profileUpdateForm.get('phone')?.dirty || profileUpdateForm.get('phone')?.touched)"
                                    class="mt-1 text-sm text-red-500">
                                    <div *ngIf="profileUpdateForm.get('phone')?.errors?.['required']">Phone number is
                                        required.</div>
                                    <div *ngIf="profileUpdateForm.get('phone')?.errors?.['pattern']">Please enter only
                                        numbers.</div>
                                </div>
                            </div>
                            <div class="form_div">
                                <label for="">Address</label>
                                <input required class="p-3 rounded-md border" placeholder="Enter Your Address"
                                    formControlName="address" type="text" required />
                            </div>
                            @if(!this.user?.email){
                            <div class="flex items-center space-x-2">
                                <input type="checkbox" id="termsCheckbox" formControlName="acceptTerms"
                                    class="w-4 h-4 rounded accent-vendblocblue" />
                                <label for="termsCheckbox" class="text-sm">Update my Profile</label>
                            </div>
                            }
                            <div class="form_div">
                                <button style="background-color: #0f172a"
                                    class="flex justify-center p-3 space-x-4 text-white duration-300 create_button hover:shadow-lg"
                                    type="submit">
                                    <span>Save</span>
                                </button>
                            </div>
                            <div class="flex flex-col justify-between mt-4 w-full">
                                <app-info
                                    textItem="This is to enable vendors contact you regarding your orders"></app-info>
                            </div>
                        </div>
                    </form>
                </div>
            </ng-template>

        </p-dialog>



        <!-- Content Div -->
        <div
            class="flex flex-row justify-between items-center px-2 pt-4 mb-20 space-x-3 w-full lg:px-4 xl:px-6 2xl:px-20 lg:space-x-6 md:items-start">
            <!-- Products Div -->
            <div class="flex flex-col md:w-[67%] w-full ">
                <div class="flex flex-col space-y-0">
                    <span class="mb-1 font-bold">Food Categories</span>
                    <div class="flex overflow-x-auto py-2">
                        @for(category of filteredCategories; track category.id) {
                        <div (click)="categoryProducts($event, category.id)" class="category_item_div group"
                            [ngClass]="{'text-white bg-vendblocpurple':activeCategory === category.id}">
                            <span class="flex px-1 whitespace-nowrap text-vendblocpurple group-hover:text-white"
                                [ngClass]="{'text-white':activeCategory === category.id}" href="">{{ category.name
                                }}</span>
                            <span class="opacity-50 text-vendblocpurple group-hover:text-white"
                                [ngClass]="{'text-white':activeCategory === category.id}">{{ category.quantity
                                }}</span>
                        </div>
                        }
                    </div>
                </div>
                <!--  [ngClass]="{
                    'grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4': cart.length > 0
                }"                     'grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5': cart.length === 0, -->
                <div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                    @for(product of filteredProducts; track product.productId) {
                    <app-order-product-card [cardName]="product.product" [iconBackgroundColor]="'vendblocpurple'"
                        [cardCount]="product.quantity.toString()" [icon]="'cart'" [product]="product"
                        (addToCartEvent)="addToCart(product)" (manageQuantity)="updateProductQuantity($event)"
                        (quantityChangedEmitter)="onProductQuantityChange($event)"
                        ></app-order-product-card>
                    } @if(filteredProducts.length === 0) {
                    <div class="text-lg text-center md:text-xl">
                        <div class="flex justify-center items-center">
                            <span>No products found matching your search.</span>
                        </div>
                    </div>
                    }
                </div>
            </div>
            <!-- My Cart -->
            <div class="md:w-[33%]" [ngClass]="cart.length === 0 ? 'hidden' : 'hidden md:block'">
                <div class="space-y-6 border md:flex md:flex-col border-grey-300 md:p-1 lg:p-3 xl:p-4">
                    <app-cart-card  (changeDeliveryAddressEvent)="toggleProfileModal()" (deleteOrderEvent)="deleteOrder($event)" [deliveryInstructionsForm]="orderInstructionsForm.controls.deliveryInstructions"
                        [vendorInstructionsForm]="orderInstructionsForm.controls.vendorInstructions" cardTitle="Cart" [user]="user"
                        (quantityChangedEmitter)="onCartQuantityChange($event)" (repeatOrderEvent)="repeatOrder()"
                        uniqueId="web" [ordersCost]="ordersCost" [outlet]="outlet" [totalCost]="totalCost"
                        [serviceCharge]="serviceCharge" [deliveryFee]="deliveryFee"
                        [deliveryLocation]="deliveryLocation" (newOrder)="newOrder()"
                        (deliveryModeChanged)="deliveryModeChanged($event)" (clearCart)="clearCart()"
                        (removeFromCart)="removeFromCart($event)" (updateCartQuantity)="updateCartItemQuantity($event)"
                        [cart]="cart" (checkout)="checkout()" [selectedDeliveryMode]="selectedDeliveryMode">
                    </app-cart-card>
                </div>
            </div>
        </div>
    </div>
</app-user-layout>