import { Component, OnDestroy, OnInit } from '@angular/core';
import { Outlet, ResponseDTO } from '@models/index';
import { RestaurantCardComponent } from "../restaurant-card/restaurant-card.component";
import { Router } from '@angular/router';
import { OutletService } from '@data-access/services';
import { Subject, debounceTime, distinctUntilChanged, first, switchMap, takeUntil } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { UserLayoutComponent } from "../layouts/user-layout/user-layout.component";

@Component({
  selector: 'app-explore',
  standalone: true,
  templateUrl: './explore.component.html',
  styleUrl: './explore.component.css',
  imports: [RestaurantCardComponent, FormsModule, UserLayoutComponent]
})
export class ExploreComponent implements OnInit, OnDestroy {

  restaurants: Outlet[] = [];
  searching: boolean = false;

  private searchTerms = new Subject<string>();
  private destroy$ = new Subject<void>();

  constructor(private router: Router, private outletService: OutletService) {
  }

  ngOnInit() {
    this.getRestuarants();
    this.triggerRestaurantSearch();
  }
  navigateHome() {
    this.router.navigate(['/']);
  }


  triggerRestaurantSearch() {
    this.searchTerms.pipe(
      debounceTime(300),

      distinctUntilChanged(),

      switchMap((query: string) => {
        // this.isLoading = true;
        this.searching = true;

        return query.trim()
          ? this.outletService.searchOutlet(query)
          : this.outletService.getOutlets();
      }),
      takeUntil(this.destroy$)
    ).subscribe({
      next: (result: ResponseDTO) => {
        // this.isLoading = false;
        if (result.status) {
          this.searching = false;
          this.restaurants = result.data as Outlet[];
        }
      },
      error: (err) => {
        // this.isLoading = false;
        console.error('Error searching restaurants', err);
      }
    });
  }

  searchRestaurant(searchQuery: string) {
    this.searchTerms.next(searchQuery);
  }

  findRestuarants(query: string) {
    this.outletService.searchOutlet(query).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.searching = false;
          this.restaurants = result.data as Outlet[];
        }
      },
      error: () => {

      }
    });
  }

  getRestuarants() {
    this.outletService.getOutlets().subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.restaurants = result.data as Outlet[];
        }
      },
      error: () => {

      }
    });

  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
