<app-auth-layout>

    <div class="flex flex-col items-center w-full">
      <form [formGroup]="loginForm" (submit)="login()">
        <div class="flex flex-col md:w-[100%] items-center">
          <h1 class="mb-2 text-2xl font-bold md:text-3xl md:mb-4">Log in to your kiosk Account</h1>
          <span class="text-sm text-center text-gray-500 md:text-base">Sign in to your account and accept orders and
            payments seamlessly</span>
        </div>
        <app-text-input-field [formSubmitted]="loginFormSubmitted" ngDefaultControl label="Username" inputId="registerEmail"
          placeHolder="Enter Your Username" [formControl]="loginKioskForm.controls.username"
          inputType="text"></app-text-input-field>
  
        <app-password-field [submitted]="loginFormSubmitted" ngDefaultControl inputId="password3"
          [passwordControl]="loginKioskForm.controls.password">
        </app-password-field>
  
        <div class="flex justify-between mt-4 w-full">
          <div class="space-x-3 text-sm md:text-base">
          </div>
          <div class="space-x-1">
            <a (click)="navigateToForgotPassword()"
            class="hover:underline text-sm md:text:base font-[500] hover:cursor-pointer text-vendblocblue dark:text-white">Forgot password ?</a>
          </div>
        </div>
        <div class="form_div">
          <button
            class="create_button flex justify-center space-x-4 bg-vendblocpurple text-white hover:bg-[#FAFAFA] hover:text-vendblocpurple hover:shadow-lg duration-300"
            type="submit">
            <span>Login</span>
          </button>
        </div>
      </form>
    </div>
  </app-auth-layout>