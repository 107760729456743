import { Component, EventEmitter, Input, ViewChild, TemplateRef } from '@angular/core';
import { CartCardComponent } from "../shared/cart-card/cart-card.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { InfoComponent } from "../info/info.component";
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { BadgeModule } from 'primeng/badge';
import { UserLayoutComponent } from "../layouts/user-layout/user-layout.component";
import { OrderProductCardComponent } from "../shared/order-product-card/order-product-card.component";
import { OrderComponent } from '@components/order/order.component';
import { AppDialogComponent } from "../shared/app-dialog/app-dialog.component";
import { AvatarImageComponent } from "../shared/avatar-image/avatar-image.component";
import { TextInputFieldComponent } from "../shared/text-input-field/text-input-field.component";
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { Assets } from '@components/shared/assets';
import { AppStarRatingComponent } from '@components/star-rating/star-rating.component';

interface SavedLocation {
  id: string;
  type: string;
  address: string;
  phone: string;
  isDefault?: boolean;
  name: string;
}

@Component({
  selector: 'app-order-v2',
  standalone: true,
  imports: [CommonModule, FormsModule,
    ReactiveFormsModule, CartCardComponent, AvatarModule, AppStarRatingComponent, AvatarGroupModule, InfoComponent, DialogModule, BadgeModule, UserLayoutComponent, OrderProductCardComponent, AppDialogComponent, TextInputFieldComponent, NzSkeletonModule],
  templateUrl: './order-v2.component.html',
  styleUrl: './order-v2.component.css'
})


export class OrderV2Component extends OrderComponent {
  
  YELLOW_MOTORBIKE_ICON = Assets.ICONS.YELLOW_MOTORBIKE_ICON
  @Input() parentCartEvent: EventEmitter<any> = new EventEmitter<any>();
  showDetailDialog: any;
  deliveryInstructionsDialog: any;
  @ViewChild('cartCardTemplate') cartCardTemplate!: TemplateRef<any>;

  savedLocations: SavedLocation[] = [
    {
      id: '1',
      type: 'office',
      address: 'Rio Nowakowska, Zabiniec 12/222',
      phone: '+79 123 456 789',
      name: 'Office'
    },
    {
      id: '2',
      type: 'home',
      address: 'Sophi Nowakowska, Zabiniec 12/222',
      phone: '+79 123 456 789',
      isDefault: true,
      name: 'My home'
    }
  ];

  selectSavedLocation(locationId: string) {
    // Handle saved location selection
  }

  addNewAddress() {
    // Handle adding new address
  }

  toggleDeliveryInstructionsDialog() {
    this.deliveryInstructionsDialog = !this.deliveryInstructionsDialog;
  }

  private timeStringToDate(timeStr: string): number {
    const [hourStr, period] = timeStr.split(' ');
    let hours = Number(hourStr);
    
    if (period === 'PM' && hours !== 12) {
        hours += 12;
    } else if (period === 'AM' && hours === 12) {
        hours = 0;
    }
    
    return hours * 60;
  }

  isOutletOpen(): boolean {
    if (!this.outlet?.opening || !this.outlet?.closing) return false;
    const now = new Date();
    const currentMinutes = (now.getHours() * 60) + now.getMinutes();
    const openingMinutes = this.timeStringToDate(this.outlet.opening);
    const closingMinutes = this.timeStringToDate(this.outlet.closing);
    
    if (openingMinutes <= closingMinutes) {
        // Normal case (e.g., 8 AM to 8 PM)
        return currentMinutes >= openingMinutes && currentMinutes <= closingMinutes;
    } else {
        // Crosses midnight (e.g., 10 PM to 2 AM)
        return currentMinutes >= openingMinutes || currentMinutes <= closingMinutes;
    }
  }

  // ngOnInit() {
  //   this.isLoading = true;
  //   this.outletService.getOutlet(this.outletId).subscribe({
  //     next: (response) => {
  //       this.outlet = response;
  //       this.isLoading = false;
  //     },
  //     error: (error) => {
  //       console.error(error);
  //       this.isLoading = false;
  //     }
  //   });
  // }
}