<div class="flex flex-col w-full">
    <div class="w-full">
        <div class="flex flex-col w-[90%] md:w-1/2 lg:w-1/3 mx-auto justify-center items-center space-y-3 py-4">
            <span class="text-2xl font-bold md:text-4xl xl:text-5xl 2xl:text-6xl">Our Restaurants</span>
            @if(!searching){
            <span
                class="text-sm md:text-lg w-[80%] xl:text-xl xl:w-[85%] 2xl:w-[70%] text-center font-normal text-gray-400">You
                can order from your preferred
                Restaurants</span>
            }
            <div class="w-full md:w-[90%] 2xl:w-[70%]">
                <input (keyup)="search($event)" [(ngModel)]="searchQuery"
                    class="py-2 pl-2 md:pl-6 px-1 w-full bg-[#7676801f] focus:outline-none rounded-3xl placeholder:text-sm md:placeholder:text-base"
                    type="text" placeholder="Search Restaurants in your city" />
            </div>
        </div>
    </div>
    @if(searching){
    <div class="w-full px-5 md:px-12 xl:px-12 2xl:px-24">
        @if(!doneSearching){
            <div class="flex justify-between mt-10 xl:mt-5 mb-1 font-semibold text-base md:text-xl 2xl:text-2xl text-[#1A1B1F]">
                <span>Searching...</span>
            </div>
        } @else {
            @if(searchResult.length > 0){
                <div class="flex justify-between mt-10 xl:mt-5 mb-1 font-semibold text-base md:text-xl 2xl:text-2xl text-[#1A1B1F]">
                    <span>Search Results</span>
                </div>
                <div class="grid grid-cols-2 grid-rows-2 gap-4 sm:grid-cols-3 lg:grid-cols-4 sm:grid-rows-3 lg:grid-rows-4">
                    @for (r of searchResult; track r.id) {
                        <app-restaurant-card outletSlug={{r.slug}} image={{r.imageUrl}} outletId={{r.id}} store={{r.store}}
                            outlet={{r.name}}></app-restaurant-card>
                    }
                </div>
            } @else {
                <div class="flex justify-between mt-10 xl:mt-5 mb-1 font-semibold text-base md:text-xl 2xl:text-2xl text-[#1A1B1F]">
                    <span>No Result found</span>
                </div>
            }
        }
    </div>
    }
    @else{
    <div class="w-full px-5 md:px-12 xl:px-12 2xl:px-24">
        <div
            class="flex justify-between mt-10 xl:mt-5 mb-1 font-semibold text-base md:text-xl 2xl:text-2xl text-[#1A1B1F]">
            <span>Nearby Restaurants</span>
        </div>
        <div class="flex mb-1">
            <div class="flex overflow-x-auto">
                @for (r of restaurants; track r.id) {
                <app-restaurant-card image={{r.imageUrl}} outletSlug={{r.slug}} outletId={{r.id}} store={{r.store}}
                    outlet={{r.name}}></app-restaurant-card>
                }
            </div>
        </div>
    </div>
    }
</div>
