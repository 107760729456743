<a (click)="navigateToOutlet()" class="cursor-pointer">
    <div class="w-[300px] rounded-lg shadow-sm hover:shadow-md transition-shadow">
        <!-- Image Container -->
        <div class="relative h-[120px]">
            <img [src]="banner || DEFAULT_RESTAURANT_IMAGE" [alt]="store"
                class="object-cover w-full h-full rounded-t-lg" />
            <!-- Profile Image -->
            <div class="rounded-full">
                <img [src]="icon || DEFAULT_RESTAURANT_IMAGE"
                    class="absolute object-cover rounded-full left-3 -bottom-6 w-[52px] h-[52px] border-[2.5px] border-white border-solid"
                    [alt]="store" />
            </div>

        </div>

        <!-- Content Container -->
        <div
            class="p-3.5 space-y-1.5 border-b border-r border-l border-vendblocprimayGrey rounded-bl-[6.56px] rounded-br-[6.56px]">
            <!-- Restaurant Name with proper left padding to align with logo -->
            <h3 class="pl-14 text-sm font-semibold text-gray-800 dark:text-gray-300">{{store}}</h3>

            <!-- Delivery Info -->
             <div class=" flex justify-between items-center text-xs text-gray-500">
                <div class="flex gap-2 items-center">

                    <img [src]="MOTORBIKE_ICON" alt="">
                    <div>
                        {{deliveryTime}} mins delivery
                    </div>

                </div>
                <h3 class="text-gray-800 dark:text-gray-500">Fee ₦{{deliveryFee}}</h3>
            </div>
            <div class="flex items-center gap-2.5">
                <app-app-star-rating [currentRating]="rating" [readonly]="true"></app-app-star-rating>
                <div>
                    <span class="text-[12px] text-[#999]">
                        {{rating}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</a>