import { Component, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { AuthLayoutComponent } from "../../layouts/auth-layout/auth-layout.component";
import { CommonModule } from '@angular/common';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { PasswordFieldComponent,TextInputFieldComponent } from '@components/shared';
import { LoginComponent } from '../login/login.component';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService, KioskService, LoadingService } from '@data-access/services';
import { SessionTimeoutService } from '../../../services/sesstion-timeout.service';


@Component({
  selector: 'app-login-kiosk',
  standalone: true,
  imports: [AuthLayoutComponent, CommonModule, ReactiveFormsModule, PasswordFieldComponent,TextInputFieldComponent],
  templateUrl: './login-kiosk.component.html',
  styleUrl: './login-kiosk.component.css'
})
export class LoginKioskComponent extends LoginComponent {
  override isKiosk: boolean = true;

  constructor(authService: AuthService, toastr: ToastrService,
    public override router: Router, kioskService: KioskService, 
    loadingService: LoadingService, zone: NgZone, fb: FormBuilder,
    @Inject(PLATFORM_ID) public override platformId: Object, sessionTimeoutService: SessionTimeoutService){
    super(
      authService,
      toastr,
      router,
      kioskService,
      loadingService,
      zone, 
      fb,
      platformId,
      sessionTimeoutService
    )
  }
}
