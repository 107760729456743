import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RestaurantCardComponent } from '@components/restaurant-card/restaurant-card.component';
import { OutletService } from '@data-access/services';
import { Outlet, ResponseDTO } from '@models/index';
import { first } from 'rxjs';
import { UserLayoutComponent } from '@components/layouts/user-layout/user-layout.component';
@Component({
    selector: 'app-home',
    standalone: true,
    templateUrl: './home.component.html',
    styleUrl: './home.component.css',
    imports: [RestaurantCardComponent, UserLayoutComponent]
})
export class HomeComponent {

    constructor(private outletService: OutletService, private router: Router) { }

    restaurants: Outlet[] = [];

    ngOnInit() {
        this.getRestuarants();
    }

    navigateToExplore() {
        this.router.navigate(['/explore']);
    }

    getRestuarants() {
        this.outletService.getOutlets().pipe(first()).subscribe({
            next: (result: ResponseDTO) => {
                if (result.status) {
                    this.restaurants = result.data as Outlet[];
                }
            },
            error: () => {
            }
        });
    }
}
