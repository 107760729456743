<app-header></app-header>
<div class="px-5 md:px-12 xl:px-12 2xl:px-24 py-[22px]">
   
</div>
<div class="flex flex-col justify-center items-center w-full h-full">
    <div class="flex flex-col w-full lg:w-[55%] h-full justify-center items-center pt-[50%] lg:pt-[15%] xl:pt:20 px-8 md:px-24 lg:px-20 xl:px-28">
       <h1 class="justify-center text-primary items-center text-4xl font-bold text-center">Page Not Found</h1>
       <p class="text-lg text-primary text-center text-gray-500">The page you are looking for does not exist. It might have been moved or deleted.</p>
       <button class=" justify-center items-center w-[200px] px-4 py-2 mt-4 text-white rounded-md bg-vendblocpurple" (click)="goBack()">Back to Home</button>
    </div>
</div>