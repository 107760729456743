import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Assets } from '@components/shared/assets';
import { RatingModule } from 'primeng/rating';
import { AppStarRatingComponent } from "../star-rating/star-rating.component";

@Component({
  selector: 'app-restaurant-card',
  standalone: true,
  imports: [RatingModule, FormsModule, AppStarRatingComponent],
  templateUrl: './restaurant-card.component.html',
  styleUrl: './restaurant-card.component.css'
})
export class RestaurantCardComponent {
  DEFAULT_RESTAURANT_IMAGE = Assets.IMAGES.DEFAULT_RESTAURANT_IMAGE;
  MOTORBIKE_ICON = Assets.ICONS.MOTORBIKE_ICON;
  @Input() outlet : string = ''
  @Input() store : string = ''
  @Input() outletId : string = ''
  @Input() outletSlug : string = ''
  @Input() storeId : string = ''
  @Input() banner : string = ''
  @Input() icon : string = ''
  @Input() rating: number = 4.5;
  @Input() deliveryTime: number = 15;
  @Input() deliveryFee: number = 300;

  constructor(private router:Router){}

  navigateToOutlet(){
    this.router.navigate(['/order/'+this.outletSlug]);
  }  
}

