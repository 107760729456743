<div class="flex flex-col min-h-screen">

<!-- <app-header></app-header> -->

<div class="flex-1">
    <router-outlet></router-outlet>
    <app-loading></app-loading>
    <!-- Other app content -->
    <app-confirm-dialog></app-confirm-dialog>
</div>

<app-footer></app-footer>
</div>
