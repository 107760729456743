<app-admin-layout>
    <app-table [cols]="tableCols" [enableShow]="true" (showButtonPressed)="showDialog($event)" [tableData]="orders"
        tableNamePlural="Pick-Ups" tableNameSingular="Pick-Up"></app-table>


    <p-dialog header="Order Detail" [(visible)]="dialogVisible" [modal]="true" [breakpoints]="{ '1000px': '90vw' }"
        [style]="{ width: '50vw', height: '500px' }" [draggable]="false" [resizable]="false">
        <div class="flex flex-col space-y-2">
            <div class="flex">
                @if(currentOrder?.status === "AwaitingPickUp" || currentOrder?.status === "Pending"){
                @if(confirmPickUp){
                <button (click)="pickUpOrder(currentOrder?.id)" class="px-4 py-2 text-white rounded-sm bg-vendblocblue"
                    type="submit">Confirm Pick Up</button>
                }@else {
                <button (click)="pickUpEvent()" class="px-4 py-2 text-white rounded-sm bg-vendblocblue"
                    type="submit">Pick Up</button>
                }
                }@else {
                <button (click)="cancelPickup(currentOrder?.id)" class="px-4 py-2 text-white rounded-sm bg-vendblocblue"
                    type="submit">Cancel Pick Up</button>
                }
            </div>
            <div class="flex justify-between">
                <span>Store : {{currentOrder?.store }}-({{currentOrder?.outlet}})</span>
                <span>Date : {{currentOrder?.formattedDate}}</span>
            </div>
            <div class="flex justify-between">
                <span>Address : {{currentOrder?.customer?.address}}</span>
                <span>Phone : {{currentOrder?.customer?.phone}}</span>
            </div>

            <div>
                <p-table [value]="currentOrder?.products" [tableStyle]="{ 'min-width': '30rem' }">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Quantity</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
                        <tr>
                            <td>{{rowIndex + 1 }}</td>
                            <td>{{ product.product }}</td>
                            <td>{{ product.orderQuantity }}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </p-dialog>

</app-admin-layout>